import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Box, Typography } from '@mui/material';

export const LinkExpired = () => {
  return (
    <IonPage data-auth="signed-out">
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Link Expired</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <Box sx={{ padding: 2 }}>
          <IonHeader collapse="condense">
            <IonToolbar className="inline-toolbar">
              <IonTitle size="large">Link Expired</IonTitle>
            </IonToolbar>
          </IonHeader>
          <Box
            sx={{
              padding: '10px 20px',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              maxWidth: 500,
              margin: 'auto',
            }}
          >
            <Typography sx={{ lineHeight: 1.5 }}>
              The link you used has expired. For your security, we just sent a
              fresh link the email or phone number you used.
            </Typography>

            <Typography sx={{ lineHeight: 1.5 }}>
              Please find the new email or text and tap the link to sign in.
            </Typography>
          </Box>
        </Box>
      </IonContent>
    </IonPage>
  );
};
