import { FilterList, Search } from '@mui/icons-material';
import { Box, Button, Input, inputClasses } from '@mui/joy';

import { Add } from '@components/icons/Add';
import { useOrganizationPermissions } from '@features/Organization/organizationSlice';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';

export const ContactsHeader = () => {
  const { setEditContactModalOpen } = useContactsProvider();
  const permissions = useOrganizationPermissions();
  const canCreate =
    permissions?.contacts?.canCreate ||
    permissions?.accountPermissions?.contacts?.canCreate;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
        padding: '0 30px 0 0px',

        '& > div': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
        },
      }}
    >
      <Box>
        <Input
          variant="search"
          placeholder="Search contacts"
          startDecorator={<Search />}
          sx={{
            background: 'transparent',
            border: '1px solid #E0E0E0',
            boxShadow: 'none',
            [`&.${inputClasses.focused} .${inputClasses.startDecorator}`]: {
              svg: {
                color: 'black',
              },
            },
          }}
        />

        <Button
          startDecorator={<FilterList sx={{ fontSize: 18 }} />}
          variant="plain"
          size="sm"
          sx={{ paddingLeft: '7px' }}
        >
          Filter
        </Button>
      </Box>
      <Box>
        {canCreate ? (
          <Button
            onClick={() => setEditContactModalOpen(true)}
            startDecorator={<Add />}
          >
            New contact
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};
