import { useIonRouter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';

import { signInWithToken } from '@utils/firebase';

import { UseUserQueryReturn } from './useUserQuery';

interface UseSetupUserProps {
  settingUpUser: boolean;
  setSettingUpUser: (input: boolean) => void;
  userQuery: UseUserQueryReturn;
}

export const useTokenAuth = ({
  settingUpUser,
  setSettingUpUser,
  userQuery,
}: UseSetupUserProps) => {
  const token = useSearchParam('t');
  const [loading, setLoading] = useState(false);
  const [promise, setPromise] = useState<Promise<Response> | null>(null);
  const router = useIonRouter();

  useEffect(() => {
    if (
      !token ||
      userQuery.loading ||
      userQuery.data?.userId ||
      settingUpUser ||
      promise
    ) {
      return;
    }

    setSettingUpUser(true);
    setLoading(true);
    setPromise(
      fetch(`${process.env.REACT_APP_API_URL}/fetchAuthToken`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      })
    );
  }, [
    token,
    userQuery.loading,
    userQuery.data?.userId,
    setSettingUpUser,
    settingUpUser,
    promise,
  ]);

  useEffect(() => {
    let mounted = true;

    if (!promise) {
      return;
    }

    (async () => {
      try {
        const response = await promise;
        const data = await response.json();

        if (mounted) {
          if (data.expired) {
            router.push('/link-expired', 'none');
          }

          if (!data.token) {
            throw new Error('Token not found');
          }

          await signInWithToken(data.token);
          setPromise(null);
        }
      } catch (error) {
        console.log('an error occured', { error });
        console.error(error);
      } finally {
        setLoading(false);
        setSettingUpUser(false);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [promise, setSettingUpUser, router]);

  return { loading };
};
