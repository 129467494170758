import {
  StripeConnectInstance,
  loadConnectAndInitialize,
} from '@stripe/connect-js';
import { ConnectComponentsProvider } from '@stripe/react-connect-js';
import { useEffect, useRef, useState } from 'react';

import {
  useOrganizationId,
  useOrganizationPermissions,
  useUser,
} from '@features/Organization/organizationSlice';
import { useMutation } from '@models/mutations/useMutation';

interface StripeConnectProps {
  children: React.ReactNode;
}

const publishableKey = process.env.REACT_APP_PUBLIC_STRIPE_KEY;

if (!publishableKey) {
  console.log('REACT_APP_PUBLIC_STRIPE_KEY is not set');
}

export const StripeConnectProvider = ({ children }: StripeConnectProps) => {
  const ref = useRef<boolean>();
  const user = useUser();
  const organizationId = useOrganizationId();
  const permissions = useOrganizationPermissions();

  const [stripeConnectInstance, setStripeConnectInstance] = useState<
    StripeConnectInstance | undefined
  >();

  const [createBillingPortalSessionMutation] = useMutation(
    'createBillingPortalSession'
  );

  useEffect(() => {
    if (
      ref.current ||
      !user ||
      !organizationId ||
      !permissions?.organizations.canUpdateOrganization ||
      !publishableKey
    ) {
      return;
    }

    ref.current = true;
    setStripeConnectInstance(
      loadConnectAndInitialize({
        publishableKey,
        fetchClientSecret: async () => {
          const result = await createBillingPortalSessionMutation({
            flow: 'fetchAccountSession',
          });

          const clientSecret = result.response?.meta?.clientSecret;

          if (!clientSecret) {
            throw new Error('Client secret not found');
          }

          return clientSecret;
        },
        appearance: {
          variables: {
            colorPrimary: '#000000',
            spacingUnit: '11px',
          },
        },
      })
    );
  }, [
    user,
    organizationId,
    createBillingPortalSessionMutation,
    permissions?.organizations.canUpdateOrganization,
  ]);

  return (
    <ConnectComponentsProvider
      // Lie to TypeScript to avoid a type error as Stripes Connect JS is not typed correctly
      connectInstance={stripeConnectInstance as StripeConnectInstance}
    >
      {children}
    </ConnectComponentsProvider>
  );
};
