import { AvailableFeatureFlags } from '@shared/models/featureFlags';

/**
 * TODO: Add deploy script that ensures all these flags exist on global feature flags
 */
export const initialGlobalFeatureFlags: AvailableFeatureFlags = {
  messaging: false,
  payroll: false,
  schedulingV1_1Enabled: false,
  schedulingV1_2Enabled: false,
  schedulingV1_3Enabled: false,
  schedulingV1_4Enabled: false,
  crmFunnelsEnabled: false,
  flatFeeEnabled: false,
  lessonNotesV1_1Enabled: false,
  calendarDotsEnabled: false,
  allSettingsEnabled: false,
  multiOrganizationSupport: false,
  supportDocsEnabled: false,
  userNotificationsEnabled: false,
  preferencesEnabled: false,
  multiplePlansEnabled: false,
  customCancelTypeEnabled: false,
  allowNonTeachersToInvite: false,
  lessonNoteEmojiPickerEnabled: false,
  showFeatureFlagsEnabled: false,
  allSidebarItemsEnabled: false,
  viewThemeEnabled: false,
  allLessonNoteFeaturesEnabled: false,
  newClientUX: false,
};
