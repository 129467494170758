import { doc } from 'firebase/firestore';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { usePrevious } from 'react-use';

import { usersCollection } from '@models/users/model';
import { signOut, useFirebaseAuthState } from '@utils/firebase';

interface UseUserQueryProps {
  settingUpUser: boolean;
}

export const useUserQuery = ({ settingUpUser }: UseUserQueryProps) => {
  const [user, loadingAuthUser] = useFirebaseAuthState();
  const posthog = usePostHog();

  const [userData, userLoading] = useDocumentData(
    !user ? null : doc(usersCollection, user.uid)
  );

  const previousUser = usePrevious(user);
  const stillLoading = previousUser === null && user !== null;

  useEffect(() => {
    if (user && !userData && !userLoading && !settingUpUser) {
      (async () => {
        try {
          await user.reload();
        } catch (error) {
          if (
            error &&
            typeof error === 'object' &&
            'code' in error &&
            error.code === 'auth/user-token-expired'
          ) {
            console.log(
              "Signing out user because of 'auth/user-token-expired'"
            );

            await signOut();
          }
        }
      })();
    }
  }, [user, userData, userLoading, settingUpUser]);

  const [identified, setIdentified] = useState(false);
  useEffect(() => {
    if (identified) {
      return;
    }

    const userId = user?.uid;

    if (userId) {
      setIdentified(true);
      posthog.identify(userId, { email: user.email });
    }
  }, [user, identified, posthog]);

  return {
    data: userData,
    loading: loadingAuthUser || userLoading || stillLoading,
  };
};

export type UseUserQueryReturn = ReturnType<typeof useUserQuery>;
