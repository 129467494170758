import { RawPermissions } from '@shared/models/permissions';
import { RawUser } from '@shared/models/users';
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { permissionsCollection } from '@models/permissions/model';

interface UsePermissionsQueryProps {
  user: RawUser | undefined;
  organizationId: string | undefined;
}

export const usePermissionsQuery = ({
  user,
  organizationId,
}: UsePermissionsQueryProps): {
  data: RawPermissions | undefined;
  loading: boolean;
} => {
  const [organizationPermissionData, organizationPermissionsLoading] =
    useDocumentData(
      !user || !organizationId
        ? null
        : doc(permissionsCollection, `${organizationId}.${user.userId}`)
    );

  const [accountPermissions, accountPermissionsLoading] = useDocumentData(
    !user || !organizationId || !user.organizationAccounts[organizationId]?.[0]
      ? null
      : doc(permissionsCollection, `${organizationId}.accounts.${user.userId}`)
  );

  const loading = organizationPermissionsLoading || accountPermissionsLoading;

  if (!organizationPermissionData) {
    return { data: undefined, loading };
  }

  return {
    data: accountPermissions
      ? { ...organizationPermissionData, accountPermissions }
      : organizationPermissionData,
    loading,
  };
};
