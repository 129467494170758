import dayjs from 'dayjs';
import {
  QueryFieldFilterConstraint,
  Timestamp,
  and,
  or,
  query,
  where,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

import { useUserData } from '@components/UserData/useUserData';
import {
  useOrganizationId,
  useOrganizationPermissions,
} from '@features/Organization/organizationSlice';

import { eventsCollection } from './model';
import { parseEvent } from './parseEvent';

export const notEmpty = <T>(event: T | null | undefined): event is T =>
  Boolean(event);

interface UseEventsQueryProps {
  currentDate: Date;
  constraints?: QueryFieldFilterConstraint[];
  skip?: boolean;
  skipAccountFilter?: boolean;
}

export const useEventsQuery = ({
  currentDate,
  constraints,
  skip,
  skipAccountFilter,
}: UseEventsQueryProps) => {
  const organizationId = useOrganizationId();
  const permissions = useOrganizationPermissions();
  const canListOrg = permissions?.events.canList;
  const canListAccountOnly = permissions?.accountPermissions?.events.canList;
  const canList = canListOrg || canListAccountOnly;
  const { userContact } = useUserData();

  const [eventsSnapshot, loading, error] = useCollection(
    !organizationId || skip || !canList
      ? null
      : query(
          eventsCollection,
          and(
            ...(canList ? [where('organizationId', '==', organizationId)] : []),
            ...(canListAccountOnly && !skipAccountFilter
              ? [where('accountIds', 'array-contains', userContact?.accountId)]
              : []),
            or(
              where(
                'end',
                '>=',
                Timestamp.fromDate(
                  dayjs(currentDate).subtract(1, 'day').startOf('day').toDate()
                )
              ),
              where('ongoing', '==', true)
            ),
            ...(constraints || [])
          )
        )
  );

  const parsedEvents = useMemo(
    () =>
      eventsSnapshot?.docs
        ?.map(parseEvent)
        .filter(notEmpty)
        .sort((a, b) => a.start.getTime() - b.start.getTime()),
    [eventsSnapshot]
  );

  const [cachedEvents, setCachedEvents] = useState(parsedEvents);

  useEffect(() => {
    if (parsedEvents) {
      setCachedEvents(parsedEvents);
    }
  }, [parsedEvents]);

  const events = parsedEvents || cachedEvents;

  return [events, loading, error] as const;
};
