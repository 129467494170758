import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogContent,
  Divider,
  IconButton,
  buttonClasses,
} from '@mui/joy';
import {
  ClickAwayListener,
  Drawer,
  backdropClasses,
  dialogContentClasses,
  paperClasses,
} from '@mui/material';
import { useCallback } from 'react';
import { useRouteMatch } from 'react-router';

import { betaContactsSlug } from '@components/Tabs/tabs.routes';
import { useOrganizationSlug } from '@features/Organization/organizationSlice';

import { useContactsProvider } from '../ContactsProvider/useContactsProvider';
import { ConnectedContacts } from './ConnectedContacts';
import { ContactDrawerHeader } from './ContactDrawerHeader';
import { ContactDrawerInfo } from './ContactDrawerInfo';
import { ContactDrawerMenu } from './ContactDrawerMenu';
import { ContactDrawerPaymentDetails } from './ContactDrawerPaymentDetails';
import { ContactDrawerNotebook } from './ContractDrawerNotebook';
import { LessonHistory } from './LessonHistory';

export const ContactDrawer = () => {
  const { selectedContactId, setSelectedContactId, setEditContactModalOpen } =
    useContactsProvider();
  const match = useRouteMatch(`/:organizationSlug/${betaContactsSlug}`);
  const organizationSlug = useOrganizationSlug();

  const onClose = useCallback(() => {
    setSelectedContactId(undefined);
  }, [setSelectedContactId]);

  const onClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const element =
        event.target instanceof HTMLElement ||
        event.target instanceof SVGSVGElement ||
        event.target instanceof SVGPathElement
          ? event.target
          : null;

      if (
        match &&
        element?.closest('body') &&
        !element?.closest(
          [
            '[data-is-contact-row]',
            '[data-contact-sidebar]',
            '[data-contact-sidebar-menu]',
            '[data-testid="delete-confirmation-modal"]',
            '[data-testid="delete-contact-confirmation-modal"]',
            '[data-testid="edit-contact-modal"]',
            `[data-prevent-close]`,
            `[router-link^="/${organizationSlug}/${betaContactsSlug}"]`,
            `.${backdropClasses.root}`,
          ].join(', ')
        )
      ) {
        onClose();
      }
    },
    [match, onClose, organizationSlug]
  );

  return (
    <Drawer
      open={Boolean(selectedContactId)}
      variant="persistent"
      onClose={onClose}
      anchor="right"
      data-contact-sidebar
      sx={{
        [`& .${paperClasses.root}`]: {
          marginTop: '58px',
          width: '500px',
        },
        [`& .${dialogContentClasses.root}`]: {
          paddingBottom: '60px',
        },
      }}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 37px 20px 40px',
            position: 'relative',
          }}
        >
          <IconButton sx={{ marginLeft: '-10px' }} onClick={onClose}>
            <Close />
          </IconButton>

          <Box sx={{ display: 'flex', gap: '10px' }}>
            <ContactDrawerMenu />

            <Button
              variant="outlined"
              onClick={() => setEditContactModalOpen(true)}
            >
              Edit
            </Button>
          </Box>
        </Box>
      </ClickAwayListener>
      <ClickAwayListener onClickAway={onClickAway}>
        <DialogContent
          sx={{
            padding: '0 40px',
            display: 'flex',
            flexDirection: 'column',
            gap: '30px',

            [`& .${buttonClasses.root}:not(.sx)`]: {
              minHeight: '49px',
            },
          }}
        >
          <ContactDrawerHeader />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            <ContactDrawerInfo />
            <ConnectedContacts />
          </Box>

          <Divider />

          <ContactDrawerPaymentDetails />

          <Divider />

          <LessonHistory />

          <ContactDrawerNotebook />
        </DialogContent>
      </ClickAwayListener>
    </Drawer>
  );
};
